export default {
  masterChef: {
    43114: '0x9984d70D5Ab32E8e552974A5A24661BFEfE81DbE',
    43113: '0xf0b6dB3ee1a7349EA8D3A38Ba839D255cD75eDdF',
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x73feaa1eE314F8c655E354234017bE2193C9E24E',
  },
  sousChef: {
    97: '',
    56: '',
  },
  lottery: {
    97: '',
    56: '',
  },
  lotteryNFT: {
    97: '',
    56: '',
  },
  multiCall: {
    43114: '0xeC03fE71EcE55684a3cbfCF74a102a94d005A110',
    43113: '0x9A4813622ee8bF1445F442BbF4D6878613e51F91',
    56: '',
    97: '',
  },
  pancakeProfile: {
    56: '',
    97: '',
  },
  pancakeRabbits: {
    56: '',
    97: '',
  },
  bunnyFactory: {
    56: '',
    97: '',
  },
  claimRefund: {
    56: '',
    97: '',
  },
  pointCenterIfo: {
    56: '',
    97: '',
  },
  bunnySpecial: {
    56: '',
    97: '',
  },
  tradingCompetition: {
    56: '',
    97: '',
  },
  easterNft: {
    56: '',
    97: '',
  },
  cakeVault: {
    56: '',
    97: '',
  },
  predictions: {
    56: '',
    97: '',
  },
  chainlinkOracle: {
    56: '',
    97: '',
  },
}
