import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { Button, useModal, IconButton, AddIcon, MinusIcon, Skeleton } from '@hurricaneswap/uikit'
import { useLocation } from 'react-router-dom'
import UnlockButton from 'components/UnlockButton'
import { useWeb3React } from '@web3-react/core'
import { useFarmUser } from 'state/hooks'
import { FarmWithStakedValue } from 'views/Farms/components/FarmCard/FarmCard'
import { useTranslation } from 'contexts/Localization'
import { useApprove } from 'hooks/useApprove'
import { getBep20Contract } from 'utils/contractHelpers'
import { BASE_ADD_LIQUIDITY_URL } from 'config'
import getLiquidityUrlPathParts from 'utils/getLiquidityUrlPathParts'
import { getBalanceNumber } from 'utils/formatBalance'
import useStake from 'hooks/useStake'
import useUnstake from 'hooks/useUnstake'
import useWeb3 from 'hooks/useWeb3'
import { BIG_TEN } from 'utils/bigNumber'
import BigNumber from 'bignumber.js'

import DepositModal from '../../DepositModal'
import WithdrawModal from '../../WithdrawModal'
import { ActionContainer, ActionTitles, ActionContent, Earned, Title, Subtle } from './styles'

const UsdToShow = styled.div`
font-size: 12px;
color: ${({ theme }) => theme.colors.textSubtle};
`
const IconButtonWrapper = styled.div`
  display: flex;
`
/* const CustomizedAddIcon = styled(AddIcon)`
  svg {
    fill: #FFF!important;
  }

`

const CustomizedMinusIcon = styled(MinusIcon)`
svg {
  fill: #FFF!important;
}
` */

interface StackedActionProps extends FarmWithStakedValue {
  userDataReady: boolean
}

const Staked: React.FunctionComponent<StackedActionProps> = ({
  pid,
  lpSymbol,
  lpAddresses,
  quoteToken,
  token,
  userDataReady,
  lpTotalSupply,
  liquidity
}) => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  let slogan 
  let linkAddress 
  if (lpSymbol === 'HCT'){
    slogan =  'Trade on V2'
    linkAddress = 'https://hurricaneswap.com/#/swap'
  }else if(lpSymbol === 'xHCT'){
    slogan = 'Deposit on V2'
    linkAddress = 'https://hurricaneswap.com/#/stake'
  }else{
    slogan = 'Migrate'
    linkAddress = 'https://v1.hurricaneswap.com/#/migrate'
  }
  const [requestedApproval, setRequestedApproval] = useState(false)
  const { allowance, tokenBalance, stakedBalance } = useFarmUser(pid)
  const { onStake } = useStake(pid)
  const { onUnstake } = useUnstake(pid)
  const web3 = useWeb3()
  const location = useLocation()

  const isApproved = account && allowance && allowance.isGreaterThan(0)

  const lpAddress = lpAddresses[process.env.REACT_APP_CHAIN_ID]
  const liquidityUrlPathParts = getLiquidityUrlPathParts({
    quoteTokenAddress: quoteToken.address,
    tokenAddress: token.address,
  })
  // const addLiquidityUrl = `${BASE_ADD_LIQUIDITY_URL}/${liquidityUrlPathParts}`
  const addLiquidityUrl  =  lpAddress === '0x75B797a0ed87F77bB546F3A3556F18fC35a01140' ? `https://hurricaneswap.com/#/stake`:`${BASE_ADD_LIQUIDITY_URL}/${liquidityUrlPathParts}`

  const displayBalance = useCallback(() => {
    const stakedBalanceNumber = getBalanceNumber(stakedBalance)
    return stakedBalanceNumber
  }, [stakedBalance])
  let StakeUsdt
  try {
    const totalLpPrice = new BigNumber(lpTotalSupply)
    // @ts-ignore
      StakeUsdt =  stakedBalance/totalLpPrice * liquidity
    }catch (e){
      StakeUsdt = 0 
    }

//  console.log(displayBalance(),totalLpPrice,liquidity.toNumber(),displayBalance()/totalLpPrice * liquidity.toNumber())
  const [onPresentDeposit] = useModal(
    <DepositModal max={tokenBalance} onConfirm={onStake} tokenName={lpSymbol} addLiquidityUrl={addLiquidityUrl} />,
  )
  const [onPresentWithdraw] = useModal(<WithdrawModal max={stakedBalance} onConfirm={onUnstake} tokenName={lpSymbol} />)

  const lpContract = getBep20Contract(lpAddress, web3)

  const { onApprove } = useApprove(lpContract)

  const handleApprove = useCallback(async () => {
    try {
      setRequestedApproval(true)
      await onApprove()
      setRequestedApproval(false)
    } catch (e) {
      console.error(e)
    }
  }, [onApprove])

  if (!account) {
    return (
      <ActionContainer>
        <ActionTitles>
          <Subtle>{t('START FARMING')}</Subtle>
        </ActionTitles>
        <ActionContent>
          <UnlockButton width="100%" />
        </ActionContent>
      </ActionContainer>
    )
  }

  if (isApproved) {
    if (stakedBalance.gt(0)) {
      return (
        <ActionContainer>
          <ActionTitles>
            <Title>{lpSymbol} </Title>
            <Subtle>{t('STAKED')}</Subtle>
          </ActionTitles>
          <ActionContent>
            <div>
              {/* @ts-ignore */}
              <Earned>{ displayBalance() >0  && displayBalance()< 0.001 ? '<0.001' : displayBalance().toLocaleString()}</Earned>
              {/* @ts-ignore */}
              <UsdToShow>≈${StakeUsdt.toLocaleString()}</UsdToShow>
            </div>
            <IconButtonWrapper>
              <IconButton variant="secondary" onClick={onPresentWithdraw} mr="6px">
                <MinusIcon width="14px" />
              </IconButton>
           {/*   <IconButton
                variant="secondary"
                onClick={onPresentDeposit}
                disabled={['history', 'archived'].some((item) => location.pathname.includes(item))}
              >
                <AddIcon width="14px" />
           </IconButton> */}
            </IconButtonWrapper>
          </ActionContent>
        </ActionContainer>
      )
    }
    return (
      <ActionContainer>
        <ActionTitles>
          <Subtle>{t('STAKE')} </Subtle>
          <Title>{lpSymbol}</Title>
        </ActionTitles>
        <a href={linkAddress } rel="noreferrer" target='_blank'>
          <ActionContent>
            <Button
              width="100%"
            // onClick={onPresentDeposit}
              variant="tertiary"
              disabled={tokenBalance.eq(0)}
            >
              { t(slogan)}
            </Button> 
          </ActionContent>
        </a>
      </ActionContainer>
    )
  }

  if (!userDataReady) {
    return (
      <ActionContainer>
        <ActionTitles>
          <Subtle>{t('START FARMING')}</Subtle>
        </ActionTitles>
        <ActionContent>
          <Skeleton width={180} marginBottom={28} marginTop={14} />
        </ActionContent>
      </ActionContainer>
    )
  }

  return (
    <ActionContainer>
      <ActionTitles>
        <Subtle>{t('ENABLE FARM')}</Subtle>
      </ActionTitles>
      <ActionContent>
        <Button width="100%" disabled variant="tertiary">
          {t('Enable')}
        </Button>
      </ActionContent>
    </ActionContainer>
  )
}

export default Staked
