import tokens from './tokens'
import { FarmConfig } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    lpSymbol: 'HCT',
    lpAddresses: {
      43113: '0xEdDEB2ff49830f3aa30Fee2F7FaBC5136845304a',
      43114: '0x45C13620B55C35A5f539d26E88247011Eb10fDbd'
    },
    token: tokens.hct,
    quoteToken: tokens.hct,
  },
  {
    pid: 8,
    lpSymbol: 'xHCT',
    lpAddresses: {
      43113: '0xe81D3f95C887ce9a89198312774d4274EeC7E43f',
      43114: '0x75B797a0ed87F77bB546F3A3556F18fC35a01140'
    },
    token: tokens.xhct,
    quoteToken: tokens.xhct,
  },
  {
    pid: 1,
    lpSymbol: 'HCT-WAVAX LP',
    lpAddresses: {
      43114: '0x0bfdb2dd65ca698ed13f0cb85cfc96f513b2e345'
    },
    token: tokens.hct,
    quoteToken: tokens.wavax,
  },
  {
    pid: 2,
    lpSymbol: 'HCT-LINK.e LP',
    lpAddresses: {
      43114: '0x8e165b635ec42a021bb5d00e5db8320989fc6ea4'
    },
    token: tokens.hct,
    quoteToken: tokens.link,
  },
  {
    pid: 3,
    lpSymbol: 'HCT-AAVE.e LP',
    lpAddresses: {
      43114: '0x0f61838c9faa9650b7b4b5c5a02c97420402c341'
    },
    token: tokens.hct,
    quoteToken: tokens.aave,
  },
  {
    pid: 4,
    lpSymbol: 'HCT-WBTC.e LP',
    lpAddresses: {
      43114: '0xdc2b4594b7f6c84d99db1c2f1166b512b91bfc6c'
    },
    token: tokens.hct,
    quoteToken: tokens.wbtc,
  },
  {
    pid: 5,
    lpSymbol: 'HCT-USDT.e LP',
    lpAddresses: {
      43114: '0xce1cf707c3be3c7304cc00ee277f99be3706f2ec'
    },
    token: tokens.hct,
    quoteToken: tokens.usdt,
  },
  {
    pid: 18,
    lpSymbol: 'HCT-USDC.e LP',
    lpAddresses: {
      43114: '0xb7b2aaE266bEdd79C1380A9E2899257E4B81e4A3'
    },
    token: tokens.hct,
    quoteToken: tokens.usdc,
  },
  {
    pid: 6,
    lpSymbol: 'HCT-XAVA LP',
    lpAddresses: {
      43114: '0xb6d76420df3fd97725c6bec33db131e1442d0e8a'
    },
    token: tokens.hct,
    quoteToken: tokens.xava,
  },
  {
    pid: 7,
    lpSymbol: 'HCT-QI LP',
    lpAddresses: {
      43114: '0xb5a4e700b44e1ef266c15c19ca3e770edd8de147'
    },
    token: tokens.hct,
    quoteToken: tokens.qi,
  },
  {
    pid: 20,
    lpSymbol: 'HCT-VSO LP',
    lpAddresses: {
      43114: '0x2F0395de56fe11ACfDeE57C9557eDF7EFf339b37'
    },
    token: tokens.hct,
    quoteToken: tokens.vso,
  },
  {
    pid: 19,
    lpSymbol: 'HCT-LYD LP',
    lpAddresses: {
      43114: '0x4e3D8cCcD1082C37b77c68Dfb53e03F8ad507a7B'
    },
    token: tokens.hct,
    quoteToken: tokens.lyd,
  },
  {
    pid: 21,
    lpSymbol: 'HCT-O3 LP',
    lpAddresses: {
      43114: '0xaB355baB039CfcBC6f1F537b9f20b1090a76Cab3'
    },
    token: tokens.hct,
    quoteToken: tokens.o3,
  },
  {
    pid: 9,
    lpSymbol: 'WAVAX-USDC.e LP',
    lpAddresses: {
      43114: '0x25f21ea641F7113B86A1542eE79936ed3670cDe7'
    },
    token: tokens.wavax,
    quoteToken: tokens.usdc,
  },
  {
    pid: 10,
    lpSymbol: 'WAVAX-USDT.e LP',
    lpAddresses: {
      43114: '0x0988C37a6E73D8508dAc55803556dE8D26303353'
    },
    token: tokens.wavax,
    quoteToken: tokens.usdt,
  },
  {
    pid: 11,
    lpSymbol: 'WAVAX-WETH.e LP',
    lpAddresses: {
      43114: '0x39C5f4995bE9a7E4Ba8ed1b684Bcdcd10Ca59f17'
    },
    token: tokens.wavax,
    quoteToken: tokens.weth,
  },
  {
    pid: 12,
    lpSymbol: 'WAVAX-WBTC.e LP',
    lpAddresses: {
      43114: '0x4E10A7292E35c979ec114648CFD026d534D79f38'
    },
    token: tokens.wavax,
    quoteToken: tokens.wbtc,
  },
  {
    pid: 13,
    lpSymbol: 'WAVAX-SNOB LP',
    lpAddresses: {
      43114: '0x96B02791D92CC58aF450ED6B9aD0456fE1C12C0e'
    },
    token: tokens.wavax,
    quoteToken: tokens.snob,
  },
  {
    pid: 14,
    lpSymbol: 'USDT.e-USDC.e LP',
    lpAddresses: {
      43114: '0x6C8C7A3a700E06C70260Ac59772b7AF70b024fB2'
    },
    token: tokens.usdt,
    quoteToken: tokens.usdc,
  },
  {
    pid: 15,
    lpSymbol: 'USDT.e-WBTC.e LP',
    lpAddresses: {
      43114: '0x1E79F50DE560E405836Dd25Fa995aB816be97a82'
    },
    token: tokens.usdt,
    quoteToken: tokens.wbtc,
  },
  {
    pid: 16,
    lpSymbol: 'USDT.e-LINK.e LP',
    lpAddresses: {
      43114: '0x1Cfb49E3d9523C04A7074bFd100c79c7f47dB330'
    },
    token: tokens.usdt,
    quoteToken: tokens.link,
  },
  {
    pid: 17,
    lpSymbol: 'WAVAX-PNG LP',
    lpAddresses: {
      43114: '0x17F1F0729FB4bfc8E06F86Eb50D38aFe9cf96568'
    },
    token: tokens.wavax,
    quoteToken: tokens.png,
  },

]

export default farms
