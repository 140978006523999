const tokens = {
  avax: {
    symbol: 'AVAX',
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/logo.png"
  },
  xhct: {
    symbol: 'xHCT',
    address: {
      43114: '0x75B797a0ed87F77bB546F3A3556F18fC35a01140'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x75b797a0ed87f77bb546f3a3556f18fc35a01140/logo.png"
  },
  hct: {
    symbol: 'HCT',
    address: {
      43114: '0x45C13620B55C35A5f539d26E88247011Eb10fDbd'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x45c13620b55c35a5f539d26e88247011eb10fdbd/logo.png"
  },
  wavax: {
    symbol: 'WAVAX',
    address: {
      43114: '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xb31f66aa3c1e785363f0875a1b74e27b85fd66c7/logo.png"
  },
  link: {
    symbol: 'LINK.e',
    address: {
      43114: '0x5947BB275c521040051D82396192181b413227A3'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x5947bb275c521040051d82396192181b413227a3/logo.png"
  },
  xava: {
    symbol: 'XAVA',
    address: {
      43114: '0xd1c3f94DE7e5B45fa4eDBBA472491a9f4B166FC4'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xd1c3f94de7e5b45fa4edbba472491a9f4b166fc4/logo.png"
  },
  usdt: {
    symbol: 'USDT.e',
    address: {
      43114: '0xc7198437980c041c805A1EDcbA50c1Ce5db95118'
    },
    decimals: 6,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xc7198437980c041c805a1edcba50c1ce5db95118/logo.png",
  },
  usdc: {
    symbol: 'USDC.e',
    address: {
      43114: '0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664'
    },
    decimals: 6,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xa7d7079b0fead91f3e65f86e8915cb59c1a4c664/logo.png",
  },
  wbtc: {
    symbol: 'WBTC.e',
    address: {
      43114: '0x50b7545627a5162F82A992c33b87aDc75187B218'
    },
    decimals: 8,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x50b7545627a5162f82a992c33b87adc75187b218/logo.png",
  },
  weth: {
    symbol: 'WETH.e',
    address: {
      43114: '0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x49d5c2bdffac6ce2bfdb6640f4f80f226bc10bab/logo.png",
  },
  aave: {
    symbol: 'AAVE.e',
    address: {
      43114: '0x63a72806098Bd3D9520cC43356dD78afe5D386D9'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x63a72806098bd3d9520cc43356dd78afe5d386d9/logo.png"
  },
  qi: {
    symbol: 'QI',
    address: {
      43114: '0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x8729438eb15e2c8b576fcc6aecda6a148776c0f5/logo.png",
  },
  crv: {
    symbol: 'CRV.e',
    address: {
      43114: '0x249848BeCA43aC405b8102Ec90Dd5F22CA513c06'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x249848beca43ac405b8102ec90dd5f22ca513c06/logo.png",
  },
  snob: {
    symbol: 'SNOB',
    address: {
      43114: '0xc38f41a296a4493ff429f1238e030924a1542e50'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xc38f41a296a4493ff429f1238e030924a1542e50/logo.png",
  },
  png: {
    symbol: 'PNG',
    address: {
      43114: '0x60781c2586d68229fde47564546784ab3faca982'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x60781c2586d68229fde47564546784ab3faca982/logo.png",
  },
  vso: {
    symbol: 'VSO',
    address: {
      43114: '0x846D50248BAf8b7ceAA9d9B53BFd12d7D7FBB25a'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x846d50248baf8b7ceaa9d9b53bfd12d7d7fbb25a/logo.png",
  },
  o3: {
    symbol: 'O3',
    address: {
      43114: '0xee9801669c6138e84bd50deb500827b776777d28'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0xee9801669c6138e84bd50deb500827b776777d28/logo.png",
  },
  lyd: {
    symbol: 'LYD',
    address: {
      43114: '0x4C9B4E1AC6F24CdE3660D5E4Ef1eBF77C710C084'
    },
    decimals: 18,
    projectLink: 'https://hurricaneswap.com/',
    logoURI: "https://assets.hurricaneswap.com/blockchains/43114/0x4c9b4e1ac6f24cde3660d5e4ef1ebf77c710c084/logo.png",
  },

}

export default tokens
