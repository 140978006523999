/* eslint-disable no-param-reassign */
/* eslint-disable */
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PriceApiResponse, PriceApiThunk, PriceState } from "state/types";
import axios from "axios";
import CollectWinningsOverlay from "views/Predictions/components/RoundCard/CollectWinningsOverlay";
import hctPrice from "state/hctPrice";

const fakeData: any = {
  "updated_at": 1629949246478,
  "data": {
    "0x45C13620B55C35A5f539d26E88247011Eb10fDbd": {
      "name": "HCT",
      "symbol": "HCT",
      "price": "0",
      "price_BNB": "0"
    },
    "0xc7198437980c041c805A1EDcbA50c1Ce5db95118": {
      "name": "USDT.e",
      "symbol": "USDT.e",
      "price": "1",
      "price_BNB": "0"
    },
    "0x5947BB275c521040051D82396192181b413227A3": {
      "name": "LINK.e",
      "symbol": "LINK.e",
      "price": "0",
      "price_BNB": "0"
    },
    "0x63a72806098Bd3D9520cC43356dD78afe5D386D9": {
      "name": "AAVE.e",
      "symbol": "AAVE.e",
      "price": "0",
      "price_BNB": "0"
    },
    "0x50b7545627a5162F82A992c33b87aDc75187B218": {
      "name": "WBTC.e",
      "symbol": "WBTC.e",
      "price": "0",
      "price_BNB": "0"
    },
    "0x249848beca43ac405b8102ec90dd5f22ca513c06": {
      "name": "CRV.e",
      "symbol": "CRV.e",
      "price": "0",
      "price_BNB": "0"
    },
    "0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5": {
      "name": "QI",
      "symbol": "QI",
      "price": "0",
      "price_BNB": "0"
    },
    "0xd1c3f94DE7e5B45fa4eDBBA472491a9f4B166FC4": {
      "name": "XAVA",
      "symbol": "XAVA",
      "price": "0",
      "price_BNB": "0"
    },
    "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7": {
      "name": "WAVAX",
      "symbol": "WAVAX",
      "price": "0",
      "price_BNB": "0"
    },
    "0x75B797a0ed87F77bB546F3A3556F18fC35a01140": {
      "name": "xHCT",
      "symbol": "xHCT",
      "price": "0",
      "price_BNB": "0"
    },
    "0xc38f41a296a4493ff429f1238e030924a1542e50": {
      "name": "SNOB",
      "symbol": "SNOB",
      "price": "0",
      "price_BNB": "0"
    },
    "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664": {
      "name": "USDC.e",
      "symbol": "USDC.e",
      "price": "0",
      "price_BNB": "0"
    },
    "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB": {
      "name": "WETH.e",
      "symbol": "WETH.e",
      "price": "0",
      "price_BNB": "0"
    },
    "0x60781c2586d68229fde47564546784ab3faca982": {
      "name": "PNG",
      "symbol": "PNG",
      "price": "0",
      "price_BNB": "0"
    },
    "0x846d50248baf8b7ceaa9d9b53bfd12d7d7fbb25a": {
      "name": "VSO",
      "symbol": "VSO",
      "price": "0",
      "price_BNB": "0"
    },
    "0xee9801669c6138e84bd50deb500827b776777d28": {
      "name": "O3",
      "symbol": "O3",
      "price": "0",
      "price_BNB": "0"
    },
    "0x4c9b4e1ac6f24cde3660d5e4ef1ebf77c710c084": {
      "name": "LYD",
      "symbol": "LYD",
      "price": "0",
      "price_BNB": "0"
    }
  }
};

const initialState: PriceState = {
  isLoading: false,
  lastUpdated: null,
  data: null
};

const getPriceData = async (coinlist, priceData) => {
  const res = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=avalanche-2&vs_currencies=usd&include_market_cap=false&include_24hr_vol=false&include_24hr_change=false");

  const avaxPrice = res["data"]["avalanche-2"]["usd"];
  const fetchPrice = hctPrice();

  for (let key in coinlist) {
    let data = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${key}&vs_currencies=usd&include_market_cap=false&include_24hr_vol=false&include_24hr_change=false`);
    priceData["data"][coinlist[key]]["price"] = data["data"][key]["usd"].toString();
    // @ts-ignore
    priceData["data"][coinlist[key]]["price_BNB"] = (data["data"][key]["usd"] / avaxPrice).toString();
  }
  const data = await fetchPrice();

  if (data) {
    data.map((reserves, i) => {
      if (reserves && reserves?.reserve0 && reserves?.reserve1) {
        const { reserve0, reserve1 } = reserves;
        const hctUsdtPrice = reserve0.toString() / reserve1.toString();
        priceData["data"]["0x45C13620B55C35A5f539d26E88247011Eb10fDbd"]["price"] = hctUsdtPrice.toFixed(3).toString();
        priceData["data"]["0x45C13620B55C35A5f539d26E88247011Eb10fDbd"]["price_BNB"] = (hctUsdtPrice / avaxPrice).toFixed(3).toString();
      }
    });
  }

  return;
};

const getPriceDataByGraph = async (coinlist, priceData) => {
  // const res = await axios.post("https://api.thegraph.com/subgraphs/name/peratx/hurricane-exchange-subgraph-fuji", {
  const res = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/exchange", {
    query: `{
      tokens{
        id
        symbol
        derivedBNB
        derivedUSD
      }
    }`,
    variables: null
  });

  if (res?.data?.data?.tokens) {
    // console.log('res?.data?.data?.tokens', res?.data?.data?.tokens)
    const contractList = Object.values(coinlist)
    res?.data?.data?.tokens.forEach(i => {
      contractList.filter((j: any) => {
        if (j.toLowerCase() === i?.id.toLowerCase()) {
          priceData['data'][j]['price'] = +i.derivedUSD
          priceData['data'][j]['price_BNB'] = +i.derivedBNB
        }
      })
    })
  }


  const bar = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/bar", {
    query: `{
      bars{
        ratio
      }
    }`
  })
  const xHCTRatio: any = Object.values(bar?.data?.data?.bars)[0]
  const hctPrice = priceData.data['0x45C13620B55C35A5f539d26E88247011Eb10fDbd'].price

  priceData.data['0x75B797a0ed87F77bB546F3A3556F18fC35a01140'] = {
    "name": "xHCT",
    "symbol": "xHCT",
    "price": (xHCTRatio?.ratio && hctPrice) ? hctPrice / xHCTRatio?.ratio : 0,
    "price_BNB": "0"
  }



  return;
};

const getPriceDataByPairGraph = async (coinlist, priceData) => {
  const res = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/exchange", {
    query: `{
      pairs {
        token0 {
          symbol
          id
        }
        reserve0
        token1 {
          symbol
          id
        }
        reserve1
      }
    }`,
    variables: null
  });

  const bar = await axios.post("https://api.thegraph.com/subgraphs/name/hurricaneswap/bar", {
    query: `{
      bars{
        ratio
      }
    }`
  })

  const tokens: any = Object.values(res?.data?.data)[0]
  const xHCTRatio: any = Object.values(bar?.data?.data?.bars)[0]

  let hctPrice;
  tokens.forEach(i => {
    if ((i?.token0?.id?.toLowerCase() === '0x45c13620b55c35a5f539d26e88247011eb10fdbd'.toLowerCase()) && (i?.token1?.id?.toLowerCase() === '0xc7198437980c041c805a1edcba50c1ce5db95118'.toLowerCase())) {
      hctPrice = i?.reserve1 / i?.reserve0
    }
    if ((i?.token1?.id?.toLowerCase() === '0x45c13620b55c35a5f539d26e88247011eb10fdbd'.toLowerCase()) && (i?.token0?.id?.toLowerCase() === '0xc7198437980c041c805a1edcba50c1ce5db95118'.toLowerCase())) {
      hctPrice = i?.reserve0 / i?.reserve1
    }
    return 0
  })


  tokens && tokens.forEach((i: any) => {
    let tokenPrice = 0;
    let key = ''
    const ifHctToken = i?.token0?.id?.toLowerCase() === '0x45C13620B55C35A5f539d26E88247011Eb10fDbd'.toLowerCase() || i?.token1?.id?.toLowerCase() === '0x45C13620B55C35A5f539d26E88247011Eb10fDbd'.toLowerCase()
    const ifXHctToken = i?.token0?.id?.toLowerCase() === '0x75B797a0ed87F77bB546F3A3556F18fC35a01140'.toLowerCase() || i?.token1?.id?.toLowerCase() === '0x75B797a0ed87F77bB546F3A3556F18fC35a01140'.toLowerCase()

    if (!(ifHctToken && ifXHctToken)) {
      // 0-u
      if (i?.token0?.id?.toLowerCase() === '0xc7198437980c041c805a1edcba50c1ce5db95118'.toLowerCase()) {
        tokenPrice = i?.reserve0 / i?.reserve1
        key = i?.token1?.id
      } else
        // u-1
        if (i?.token1?.id?.toLowerCase() === '0xc7198437980c041c805a1edcba50c1ce5db95118'.toLowerCase()) {
          tokenPrice = i?.reserve1 / i?.reserve0
          key = i?.token0?.id
        } else
          // 0-h
          if (i?.token0?.id?.toLowerCase() === '0x45c13620b55c35a5f539d26e88247011eb10fdbd'.toLowerCase()) {
            tokenPrice = i?.reserve0 / i?.reserve1 * hctPrice
            key = i?.token1?.id
          } else
            // h-1
            if (i?.token1?.id?.toLowerCase() === '0x45c13620b55c35a5f539d26e88247011eb10fdbd'.toLowerCase()) {
              tokenPrice = i?.reserve1 / i?.reserve0 * hctPrice
              key = i?.token0?.id
            }
    }

    Object.keys(priceData?.data).forEach(j => {
      if (j?.toLowerCase() === key && priceData["data"][j]["price"] <= 0) {
        priceData["data"][j]["price"] = tokenPrice
      }
    })

    // @ts-ignore
    priceData.data['0x75B797a0ed87F77bB546F3A3556F18fC35a01140'] = {
      "name": "xHCT",
      "symbol": "xHCT",
      "price": (xHCTRatio?.ratio && hctPrice) ? hctPrice / xHCTRatio?.ratio : 0,
      "price_BNB": "0"
    }
  })
  return;
}


// Thunks
export const fetchPrices = createAsyncThunk<PriceApiThunk>("prices/fetch", async () => {
  // const response = await fetch('https://api.pancakeswap.info/api/v2/tokens')
  // const data = (await response.json()) as PriceApiResponse
  const coinList = {
    "hct": "0x45C13620B55C35A5f539d26E88247011Eb10fDbd",
    "USDT.e": "0xc7198437980c041c805A1EDcbA50c1Ce5db95118",
    "LINK.e": "0x5947BB275c521040051D82396192181b413227A3",
    "AAVE.e": "0x63a72806098Bd3D9520cC43356dD78afe5D386D9",
    "WBTC.e": "0x50b7545627a5162F82A992c33b87aDc75187B218",
    "WAVAX": "0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7",
    "XAVA": "0xd1c3f94DE7e5B45fa4eDBBA472491a9f4B166FC4",
    "QI": "0x8729438EB15e2C8B576fCc6AeCdA6A148776C0F5",
    "CRV.e": "0x249848beca43ac405b8102ec90dd5f22ca513c06",
    "xHct": "0x75B797a0ed87F77bB546F3A3556F18fC35a01140",
    "SNOB": "0xc38f41a296a4493ff429f1238e030924a1542e50",
    "USDC.e": "0xA7D7079b0FEaD91F3e65f86E8915Cb59c1a4C664",
    "WETH.e": "0x49D5c2BdFfac6CE2BFdB6640F4F80f226bc10bAB",
    "PNG": "0x60781c2586d68229fde47564546784ab3faca982",
    "VSO": "0x846d50248baf8b7ceaa9d9b53bfd12d7d7fbb25a",
    "O3": "0xee9801669c6138e84bd50deb500827b776777d28",
    "LYD": "0x4c9b4e1ac6f24cde3660d5e4ef1ebf77c710c084"

  };
  await getPriceDataByGraph(coinList, fakeData);
  // await getPriceDataByPairGraph(coinList, fakeData);
  const data = fakeData as PriceApiResponse;
  // console.log('data', data)
  // Return normalized token names
  return {
    updated_at: data.updated_at,
    data: Object.keys(data.data).reduce((accum, token) => {
      return {
        ...accum,
        [token.toLowerCase()]: parseFloat(data.data[token].price)
      };
    }, {})
  };
});

export const pricesSlice = createSlice({
  name: "prices",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPrices.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(fetchPrices.fulfilled, (state, action: PayloadAction<PriceApiThunk>) => {
      state.isLoading = false;
      state.lastUpdated = action.payload.updated_at;
      state.data = action.payload.data;
    });
  }
});

export default pricesSlice.reducer;
