import React, { useState, useMemo } from 'react'
import { Input ,SearchIcon} from '@hurricaneswap/uikit'
import styled from 'styled-components'
import debounce from 'lodash/debounce'


const StyledInput = styled(Input)`
  border-radius: 8px;
  margin-left: auto;
  
  background: ${({ theme }) => theme.isDark ? 'rgba(255, 255, 255, 0.2)' : 'rgba(255,255,255,0.6)'};
  border: 1px solid ${({theme})=>(theme.isDark ? '#8D80D5' : 'rgba(255, 255, 255, 0.6)')};  
  
  :focus:not(:disabled){
  border: 1px solid #5723A6;
  background: ${({ theme }) => theme.isDark ? '#1D142B' : '#FFFFFF'};
  border: 1px solid #5723A6;
  }
  
  ::-ms-input-placeholder{
    color: ${({theme})=>(theme.isDark ? 'rgba(255,255,255,0.25)' : 'rgba(77,60,85,0.5)')};
  }
  ::-moz-input-placeholder{
    color: ${({theme})=>(theme.isDark ? 'rgba(255,255,255,0.25)' : 'rgba(77,60,85,0.5)')};
  }
  ::-webkit-input-placeholder{
      color: ${({theme})=>(theme.isDark ? 'rgba(255,255,255,0.25)' : 'rgba(77,60,85,0.5)')};
  } 
   
`
const Search = styled(SearchIcon)`
  position:absolute;
  top:10px;
  right:10px;
  width:24px;
`


const InputWrapper = styled.div`
  position: relative;
  ${({ theme }) => theme.mediaQueries.sm} {
    width: 234px;
    display: block;
  }
`

const Container = styled.div<{ toggled: boolean }>``

interface Props {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const SearchInput: React.FC<Props> = ({ onChange: onChangeCallback }) => {
  const [toggled, setToggled] = useState(false)
  const [searchText, setSearchText] = useState('')

  const debouncedOnChange = useMemo(
    () => debounce((e: React.ChangeEvent<HTMLInputElement>) => onChangeCallback(e), 500),
    [onChangeCallback],
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value)
    debouncedOnChange(e)
  }

  return (
    <Container toggled={toggled}>
      <InputWrapper>
        <Search/>
        <StyledInput
          value={searchText}
          onChange={onChange}
          placeholder="Search farms"
          onBlur={() => setToggled(false)}
        />
      </InputWrapper>
    </Container>
  )
}

export default SearchInput
