import { Language } from '@hurricaneswap/uikit'

export const EN: Language = { locale: 'en-US', language: 'English', code: 'en' }
// export const CN: Language = { locale: 'zh-CN', language: '简体中文', code: 'zh' }

export const languages = {
  'en-US': EN,
//  'zh-CN': CN,
}

export const languageList = Object.values(languages)
