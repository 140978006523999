import React from 'react'
import styled from 'styled-components'
import { Tag, Flex, Heading, Image } from '@hurricaneswap/uikit'


export interface ExpandableSectionProps {
  lpLabel?: string
  multiplier?: string
  isCommunityFarm?: boolean
  farmImage?: string
  tokenSymbol?: string
  secondFarmImage?: string
}

const Wrapper = styled(Flex)`
  svg {
    margin-right: 4px;
  }
`

const MultiplierTag = styled(Tag)`
  background: ${({theme})=>(theme.colors.borderColor)};
  margin-left: 4px;
  min-width: 52px;
  min-height: 28px;
  justify-content: center;
  margin-top: 6px;
`

const IconBImage = styled(Image)`
background: #fff;
// position: absolute;
// left:20px;
width: 30px;
height: 30px;

display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;

img{
  display: inline-block;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  position: relative;
}
`

const IconImage = styled(Image)`
  position: absolute;
  left: 12px;
  top: 8px;
  // padding: 16px;
  background: #fff;

  width: 42px;
  height: 42px;

  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;

  img{
    border-radius: 50%;
    display: inline-block;
    width: calc(100% - 10px);
    height: calc(100% - 10px);
    position: relative;
  }

`

const CardHeading: React.FC<ExpandableSectionProps> = ({
  lpLabel,
  multiplier,
  farmImage,
  secondFarmImage
}) => {
  return (
    <Wrapper justifyContent="space-between" alignItems="flex-start" mb="12px">
      {/* <Image src={`../../assets/images/farms/${farmImage}.svg`} alt={tokenSymbol} width={64} height={64} /> */}
      <Flex position='relative' alignItems='flex-start'>
        <IconBImage src={secondFarmImage} alt="icon" width={40} height={40}/>
        <IconImage src={farmImage} alt="icon" width={64} height={64} />
      </Flex>
      <Flex flexDirection="column" alignItems="flex-end">
        <Heading mb="4px">{lpLabel.split(' ')[0]}</Heading>
        <Flex justifyContent="center">
          {/* {isCommunityFarm ? <CommunityTag /> : <CoreTag />} */}
          <MultiplierTag variant="textSubtle">{multiplier}</MultiplierTag>
        </Flex>
      </Flex>
    </Wrapper>
  )
}

export default CardHeading
