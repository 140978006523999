import React, { useMemo } from 'react'
import { Navbar } from '@hurricaneswap/uikit'
import { useWeb3React } from '@web3-react/core'
import { languageList } from 'config/localization/languages'
import { useTranslation } from 'contexts/Localization'
import useTheme from 'hooks/useTheme'
import useAuth from 'hooks/useAuth'
import config from './config'
import useMobile from "../../hooks/useMobile";
import UserTokens from "../UserTokens";


const VersionIcon = () => {
  return (<svg width="28" height="20" viewBox="0 0 28 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 4C0 1.79086 1.79086 0 4 0H28V16C28 18.2091 26.2091 20 24 20H0V4Z" fill="url(#paint0_linear_2354_14089)" />
    <path d="M14.432 8.456L11.552 14.12H10.904L8.012 8.456H10.016L10.892 10.532C11.084 10.98 11.192 11.328 11.216 11.576H11.24C11.272 11.288 11.38 10.94 11.564 10.532L12.44 8.456H14.432ZM18.6173 5.9V14H16.8653V9.512C16.8653 9.144 16.9013 8.868 16.9733 8.684L16.9253 8.66C16.8293 8.796 16.7013 8.9 16.5413 8.972L15.4253 9.512V7.772L16.9613 6.704L17.2133 5.9H18.6173Z" fill="white" />
    <defs>
      <linearGradient id="paint0_linear_2354_14089" x1="-0.407766" y1="7.05882" x2="20.4987" y2="19.5818" gradientUnits="userSpaceOnUse">
        <stop stopColor="#A78EEF" />
        <stop offset="0.945858" stopColor="#5B12CB" />
      </linearGradient>
    </defs>
  </svg>
  )
}

const coinList = () => {
  return <UserTokens />
}


export const getNetwork = (chainId) => {
  let network
  if (chainId === 97) {
    network = 'Test-BSC'
  } else if (chainId === 56) {
    network = 'BSC'
  } else if (chainId === 256) {
    network = 'TEST-HECO'
  } else if (chainId === 128) {
    network = 'HECO'
  } else if (chainId === 43113) {
    network = 'FUJI'
  } else {
    network = 'AVAX'
  }
  return network
}


const Menu = (props) => {
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { currentLanguage, setLanguage } = useTranslation()
  const { isDark, toggleTheme, theme } = useTheme()
  const { t } = useTranslation()
  const ifMobileForNav = useMobile(1200)
  const chainId = parseInt(process.env.REACT_APP_CHAIN_ID, 10)
  const network = getNetwork(chainId)
  // console.log(chainId,'wangluo')

  const lng = useMemo(() => {
    return currentLanguage?.code.split('-')[0]
  }, [currentLanguage])

  // <Navbar
  // links={links}
  // account={account || undefined}
  // login={login}
  // logout={deactivate}
  // isDark={isDark}
  // toggleTheme={toggleTheme}
  // currentLang={selectedLanguage?.code || ''}
  // langs={allLanguages}
  // setLang={setSelectedLanguage}
  // coinList = {UserTokens}
  // SearchToken={SearchTokens}
  // style={{ backdropFilter: 'blur(5px)', transition: 'all linear 0.1s', background: `${theme.colors.gradients.backgroundCover} fixed`, opacity: 0.85}}
  // {...props}
  // />

  return (
    <Navbar
      ifMobile={ifMobileForNav}
      account={account}
      login={login}
      logout={logout}
      isDark={isDark}
      toggleTheme={toggleTheme}
      currentLang={lng}
      langs={languageList}
      setLang={setLanguage}
      links={config}
      t={t}
      coinList={coinList}
      SearchToken={null}
      style={{ backdropFilter: 'blur(5px)', transition: 'all linear 0.1s', background: `${theme.colors.gradients.backgroundCover} fixed`, opacity: 0.85 }}
      {...props}
      network={network}
      version={VersionIcon}
    />
  )
}

export default Menu
