const config = [
  {
    label: "Home",
    href: "https://v1.hurricaneswap.com/"
  },
  {
    label: "Swap",
    href: "https://v1.hurricaneswap.com/#/swap",
  },
  {
    label: "Migrate",
    href: "https://v1.hurricaneswap.com/#/migrate",
  },
  {
    label: "Farms",
    href: "/",
  },
  {
    label: "Info",
    children: [
      {
        label: "Overview",
        href: "https://v1.hurricaneswap.com/#/info/overview",
      },
      {
        label: "Pairs",
        href: "https://v1.hurricaneswap.com/#/info/pairs",
      },
      {
        label: "Tokens",
        href: "https://v1.hurricaneswap.com/#/info/tokens",
      },
    ]
  },
];




export default config
